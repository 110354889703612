import "./production.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import ReactToPrint from "react-to-print";
import SummaryPrintableComponent from "../../components/printableComponent/productionSummaryPrintableComponent";
import LabelsPrintableComponent from "../../components/printableComponent/productionLabelsPrintableComponent";
import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import React from 'react';

import {
  doc,
  // getDoc,
  updateDoc,
  onSnapshot,
  query,
  collection,
  where,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { MASS_UNITS } from "../../constants";

const Production = () => {
  const { type, objectId } = useParams();
  const [ingredients, setIngredients] = useState([]);
  const [doughs, setDoughs] = useState([]);
  const [products, setProducts] = useState([]);
  const [lines, setLines] = useState([]);
  const [productionLists, setProductionLists] = useState([]);

  const [productionDate, setProductionDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [documentData, setDocumentData] = useState({
    selectedProductionLists: [],
    lineProductQuantities: {},
    startTimes: {},
  });

  const [documentId, setDocumentId] = useState(null);

  const organizationId = JSON.parse(localStorage.getItem("organizations"))
    .selected.id;

  const componentRef = useRef();
  const labelsComponentRef = useRef();

  const fetchItems = (collectionName, stateUpdater) => {
    return onSnapshot(
      query(
        collection(db, collectionName),
        where("organizationId", "==", organizationId)
      ),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: collectionName + "/" + doc.id,
          collection: collectionName,
          ...doc.data(),
        }));
        stateUpdater(data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const fetchProduction = () => {
    return onSnapshot(
      query(
        collection(db, "productions"),
        where("date", "==", productionDate),
        where("organizationId", "==", organizationId)
      ),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          collection: "productions",
          ...doc.data(),
        }));
        // console.log("data ", data)
        if (data.length > 0) {
          setDocumentData(data[0]);
          setDocumentId(data[0].id);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    const unsubscribeIngredients = fetchItems("ingredients", setIngredients);
    const unsubscribeDoughs = fetchItems("doughs", setDoughs);
    const unsubscribeProducts = fetchItems("products", setProducts);
    const unsubscribeLines = fetchItems("lines", setLines);
    const unsubscribeProductionLists = fetchItems(
      "productionlists",
      setProductionLists
    );
    const unsubscribeProduction = fetchProduction();

    return () => {
      unsubscribeIngredients();
      unsubscribeDoughs();
      unsubscribeProducts();
      unsubscribeLines();
      unsubscribeProductionLists();
      unsubscribeProduction();
    };
  }, [type, objectId, organizationId, productionDate]);

  function getDayOfWeek(dateString) {
    // Convert the date string to a Date object
    const date = new Date(dateString);
    // Array of days of the week
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    // Get the day of the week as a number (0-6)
    const dayIndex = date.getDay();
    // Return the name of the day
    return daysOfWeek[dayIndex];
  }

  function formatDate(dateString) {
    // Split the date string into components
    const [year, month, day] = dateString.split("-").map(Number);
    // Create a Date object in local time
    const date = new Date(year, month - 1, day); // Month is zero-based
    // Extract day, month, and year
    const formattedDay = String(date.getDate()).padStart(2, "0"); // Day
    const formattedMonth = String(date.getMonth() + 1).padStart(2, "0"); // Month
    const formattedYear = String(date.getFullYear()); // Year
    // Return the formatted date as DD-MM-YY
    return `${formattedDay}-${formattedMonth}-${formattedYear}`;
  }

  const allItems = [...ingredients, ...doughs, ...products];

  function getLineByProductRef(productRef) {
    const line = lines.find((item) => item.id === productRef);
    return line ? line : { name: "Line not found" };
  }

  function getItemByProductRef(productRef) {
    const item = allItems.find((item) => item.id === productRef);
    return item ? item : { name: "Product not found" };
  }

  const handleQuantityChange = (listId, lineId, productId, quantity) => {
    setDocumentData((prev) => {
      const updatedData = { ...prev };
      if (!updatedData.lineProductQuantities[listId]) {
        updatedData.lineProductQuantities[listId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId]) {
        updatedData.lineProductQuantities[listId][lineId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId][productId]) {
        updatedData.lineProductQuantities[listId][lineId][productId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId][productId].unit) {
        // console.log("No unit selected");
        let product = getItemByProductRef(productId);
        // Set default unit
        const defaultUnit =
          product.productUnits && product.productUnits.length > 0
            ? {
              name: product.productUnits[0].name,
              pieceCount: product.productUnits[0].pieceCount,
            }
            : { name: "Piece", pieceCount: 1 };

        updatedData.lineProductQuantities[listId][lineId][productId].unit =
          defaultUnit;
      }
      updatedData.lineProductQuantities[listId][lineId][productId].quantity =
        parseInt(quantity, 10);
      return updatedData;
    });
  };

  const handleOnHandChange = (listId, lineId, productId, quantity) => {
    setDocumentData((prev) => {
      const updatedData = { ...prev };
      if (!updatedData.lineProductQuantities[listId]) {
        updatedData.lineProductQuantities[listId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId]) {
        updatedData.lineProductQuantities[listId][lineId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId][productId]) {
        updatedData.lineProductQuantities[listId][lineId][productId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId][productId].unit) {
        // console.log("No unit selected");
        let product = getItemByProductRef(productId);
        // Set default unit
        const defaultUnit =
          product.productUnits && product.productUnits.length > 0
            ? {
              name: product.productUnits[0].name,
              pieceCount: product.productUnits[0].pieceCount,
            }
            : { name: "Piece", pieceCount: 1 };

        updatedData.lineProductQuantities[listId][lineId][productId].unit =
          defaultUnit;
      }
      updatedData.lineProductQuantities[listId][lineId][productId].onHand =
        parseInt(quantity, 10);
      return updatedData;
    });
  };

  const handleExtraChange = (listId, lineId, productId, quantity) => {
    setDocumentData((prev) => {
      const updatedData = { ...prev };
      if (!updatedData.lineProductQuantities[listId]) {
        updatedData.lineProductQuantities[listId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId]) {
        updatedData.lineProductQuantities[listId][lineId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId][productId]) {
        updatedData.lineProductQuantities[listId][lineId][productId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId][productId].unit) {
        // console.log("No unit selected");
        let product = getItemByProductRef(productId);
        // Set default unit
        const defaultUnit =
          product.productUnits && product.productUnits.length > 0
            ? {
              name: product.productUnits[0].name,
              pieceCount: product.productUnits[0].pieceCount,
            }
            : { name: "Piece", pieceCount: 1 };

        updatedData.lineProductQuantities[listId][lineId][productId].unit =
          defaultUnit;
      }
      updatedData.lineProductQuantities[listId][lineId][productId].extra =
        parseInt(quantity, 10);
      return updatedData;
    });
  };

  const handleBreakChange = (listId, lineId, productId, quantity) => {
    setDocumentData((prev) => {
      const updatedData = { ...prev };
      if (!updatedData.lineProductQuantities[listId]) {
        updatedData.lineProductQuantities[listId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId]) {
        updatedData.lineProductQuantities[listId][lineId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId][productId]) {
        updatedData.lineProductQuantities[listId][lineId][productId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId][productId].unit) {
        // console.log("No unit selected");
        let product = getItemByProductRef(productId);
        // Set default unit
        const defaultUnit =
          product.productUnits && product.productUnits.length > 0
            ? {
              name: product.productUnits[0].name,
              pieceCount: product.productUnits[0].pieceCount,
            }
            : { name: "Piece", pieceCount: 1 };

        updatedData.lineProductQuantities[listId][lineId][productId].unit =
          defaultUnit;
      }
      updatedData.lineProductQuantities[listId][lineId][productId].break =
        parseInt(quantity);
      return updatedData;
    });
  };

  const handleUnitChange = (listId, lineId, productId, event) => {
    // console.log("handle unit change: ", event.name);
    const pieceCount = event.value;
    const name = event.getAttribute("name");
    setDocumentData((prev) => {
      const updatedData = { ...prev };
      if (!updatedData.lineProductQuantities[listId]) {
        updatedData.lineProductQuantities[listId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId]) {
        updatedData.lineProductQuantities[listId][lineId] = {};
      }
      if (!updatedData.lineProductQuantities[listId][lineId][productId]) {
        updatedData.lineProductQuantities[listId][lineId][productId] = {};
      }
      updatedData.lineProductQuantities[listId][lineId][productId].unit = {
        pieceCount,
        name,
      };
      return updatedData;
    });
  };

  const removeProductionList = (listId) => {
    setDocumentData((prev) => ({
      ...prev,
      selectedProductionLists: prev.selectedProductionLists.filter(
        (id) => id !== listId
      ),
    }));
  };

  const moveProductionList = (index, direction) => {
    setDocumentData((prev) => {
      const newList = [...prev.selectedProductionLists];
      const [movedItem] = newList.splice(index, 1);
      newList.splice(index + direction, 0, movedItem);
      return {
        ...prev,
        selectedProductionLists: newList,
      };
    });
  };

  const moveProductUp = (listId, lineId, productId) => {
    setDocumentData((prev) => {
      const lineProductQuantities = prev.lineProductQuantities || {};
      // Get the products object for the specific listId and lineId
      const products = lineProductQuantities[listId]?.[lineId] || {};
      // Convert the products object to an array of entries
      const productEntries = Object.entries(products);
      const index = productEntries.findIndex(([id]) => id === productId);
      // If the index is out of range or invalid, return previous state
      if (index <= 0 || index >= productEntries.length) {
        console.log("Cannot move up: Index out of range or invalid.");
        return prev;
      }
      // Swap the products in the array
      const newProductEntries = [...productEntries];
      [newProductEntries[index - 1], newProductEntries[index]] = [
        newProductEntries[index],
        newProductEntries[index - 1],
      ];
      // Convert back to an object
      const newProducts = Object.fromEntries(newProductEntries);
      // Return updated state
      return {
        ...prev,
        lineProductQuantities: {
          ...lineProductQuantities,
          [listId]: {
            ...(lineProductQuantities[listId] || {}),
            [lineId]: newProducts,
          },
        },
      };
    });
  };

  const moveProductDown = (listId, lineId, productId) => {
    setDocumentData((prev) => {
      const lineProductQuantities = prev.lineProductQuantities || {};

      // Get the products object for the specific listId and lineId
      const products = lineProductQuantities[listId]?.[lineId] || {};

      // Convert the products object to an array of entries
      const productEntries = Object.entries(products);
      const index = productEntries.findIndex(([id]) => id === productId);

      // If the index is out of range or invalid, return previous state
      if (index < 0 || index >= productEntries.length - 1) {
        console.log("Cannot move down: Index out of range or invalid.");
        return prev;
      }

      // Swap the products in the array
      const newProductEntries = [...productEntries];
      [newProductEntries[index + 1], newProductEntries[index]] = [
        newProductEntries[index],
        newProductEntries[index + 1],
      ];

      // Convert back to an object
      const newProducts = Object.fromEntries(newProductEntries);

      // Return updated state
      return {
        ...prev,
        lineProductQuantities: {
          ...lineProductQuantities,
          [listId]: {
            ...(lineProductQuantities[listId] || {}),
            [lineId]: newProducts,
          },
        },
      };
    });
  };

  const saveProductionData = async () => {
    const data = {
      date: productionDate,
      organizationId: organizationId,
      ...documentData,
    };

    try {
      if (documentId) {
        const docRef = doc(db, "productions", documentId);
        await updateDoc(docRef, data);
      } else {
        const docRef = doc(collection(db, "productions"));
        await setDoc(docRef, data);
      }
      console.log("Document successfully written!");
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  };

  const calculateTotal = (ingredient, data, lineId, productId) => {
    // Implement the logic to calculate the total value
    let total;
    if (
      getLineByProductRef(lineId).products.find((item) => item[productId])[
        productId
      ].scrapPercent > 0
    ) {
      total =
        Math.round(
          ((Object.entries(ingredient)[0][1] *
            Math.max(
              0,
              (data.quantity ?? 0) - (data.onHand ?? 0) + (data.extra ?? 0)
            ) *
            data.unit.pieceCount) /
            MASS_UNITS[0].amount +
            ((Object.entries(ingredient)[0][1] *
              Math.max(
                0,
                (data.quantity ?? 0) - (data.onHand ?? 0) + (data.extra ?? 0)
              ) *
              data.unit.pieceCount) /
              MASS_UNITS[0].amount) *
            (getLineByProductRef(lineId).products.find(
              (item) => item[productId]
            )[productId].scrapPercent /
              100)) *
          100
        ) / 100;
    } else {
      total =
        Math.round(
          ((Object.entries(ingredient)[0][1] *
            Math.max(
              0,
              (data.quantity ?? 0) - (data.onHand ?? 0) + (data.extra ?? 0)
            ) *
            data.unit.pieceCount) /
            MASS_UNITS[0].amount) *
          100
        ) / 100;
    }
    return "Total " + total + " " + MASS_UNITS[0].abbreviation + " ";
  };
  const calculateScale = (ingredient, data) => {
    // Implement the logic to calculate the scale value
    let scale;
    scale =
      Math.round(
        ((Object.entries(ingredient)[0][1] *
          Math.max(
            0,
            (data.quantity ?? 0) - (data.onHand ?? 0) + (data.extra ?? 0)
          ) *
          data.unit.pieceCount) /
          MASS_UNITS[0].amount) *
        100
      ) / 100;
    return "Scale " + scale + " " + MASS_UNITS[0].abbreviation + " ";
  };
  const calculateScrap = (ingredient, data, lineId, productId) => {
    // Implement the logic to calculate the scrap value
    let scrapMass;
    let scrapPercent;
    if (
      getLineByProductRef(lineId).products.find((item) => item[productId])[
        productId
      ].scrapPercent > 0
    ) {
      scrapMass =
        Math.round(
          ((Object.entries(ingredient)[0][1] *
            Math.max(
              0,
              (data.quantity ?? 0) - (data.onHand ?? 0) + (data.extra ?? 0)
            ) *
            data.unit.pieceCount) /
            MASS_UNITS[0].amount) *
          (getLineByProductRef(lineId).products.find(
            (item) => item[productId]
          )[productId].scrapPercent /
            100) *
          100
        ) / 100;
    } else {
      scrapMass = 0;
    }
    if (
      getLineByProductRef(lineId).products.find((item) => item[productId])[
        productId
      ].scrapPercent > 0
    ) {
      scrapPercent = getLineByProductRef(lineId).products.find(
        (item) => item[productId]
      )[productId].scrapPercent;
    } else {
      scrapPercent = 0;
    }
    return "Scrap " + scrapMass + " (" + scrapPercent + ")%";
  };

  const calculateMixes = (ingredient, data, lineId, productId) => {
    // Fetch the line object
    const line = getLineByProductRef(lineId);
    // Check if line and line.products are defined
    if (!line || !line.products) {
      console.error("Line or its products are undefined");
      return "N/A Mixes"; // Return a fallback value
    }
    // Find the product item safely
    const productItem = line.products.find((item) => item[productId]);
    // Check if the productItem and the required property exist
    const scrapPercent = productItem ? productItem[productId]?.scrapPercent : 0;

    let total;
    if (scrapPercent > 0) {
      total =
        Math.round(
          (Object.entries(ingredient)[0][1] *
            Math.max(
              0,
              (data.quantity ?? 0) - (data.onHand ?? 0) + (data.extra ?? 0)
            ) *
            data.unit.pieceCount +
            Object.entries(ingredient)[0][1] *
            Math.max(
              0,
              (data.quantity ?? 0) - (data.onHand ?? 0) + (data.extra ?? 0)
            ) *
            data.unit.pieceCount *
            (scrapPercent / 100)) *
          100
        ) / 100;
    } else {
      total =
        Math.round(
          Object.entries(ingredient)[0][1] *
          Math.max(
            0,
            (data.quantity ?? 0) - (data.onHand ?? 0) + (data.extra ?? 0)
          ) *
          data.unit.pieceCount *
          100
        ) / 100;
    }

    let mixes = null;
    if (
      Object.keys(ingredient)[0].substring(
        0,
        Object.keys(ingredient)[0].indexOf("/")
      ) === "doughs"
    ) {
      mixes =
        Math.round(
          (total / getItemByProductRef(Object.keys(ingredient)[0]).maxMix) * 100
        ) / 100;
    }
    return mixes;
  };

  const handleStartTimeChange = (listId, lineId, value) => {
    setDocumentData((prevData) => ({
      ...prevData,
      startTimes: {
        ...prevData.startTimes,
        [listId]: {
          ...prevData.startTimes[listId],
          [lineId]: value,
        },
      },
    }));
  };

  const handleEnterKey = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submit behavior
      const formElements = Array.from(
        document.querySelectorAll("input, select")
      );
      const currentIndex = formElements.indexOf(event.target);

      if (currentIndex >= 0 && currentIndex < formElements.length - 1) {
        formElements[currentIndex + 1].focus(); // Focus on the next input or select element
      }
    }
  };

  const addMinutes = (time, minutes) => {
    const [hours, mins] = time.split(":").map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(mins + minutes);
    return date.toTimeString().substr(0, 5);
  };

  // const formatTime = (time) => {
  //   // const [hours, minutes] = time.split(":").map(Number);
  //   // return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  // };

  const summaryData = Object.entries(documentData.lineProductQuantities).map(
    ([listId, lines]) => {
      const listName =
        productionLists.find((list) => list.id === listId)?.name || "Not Found";
      const lineDetails = Object.entries(lines).map(([lineId, products]) => {
        let startTime = documentData.startTimes[listId]?.[lineId] || "00:00";
        const lineName = getLineByProductRef(lineId).name;
        const productDetails = Object.entries(products).map(
          ([productId, data]) => {
            const breakTime = parseInt(data.break)
            const productName = getItemByProductRef(productId).name;
            const totalPieces =
              Math.max(
                0,
                (data.quantity ?? 0) - (data.onHand ?? 0) + (data.extra ?? 0)
              ) * data.unit.pieceCount;
            const ingredientDetails = Object.entries(
              getItemByProductRef(productId).ingredients
            ).map(([index, ingredient]) => {
              const ingredientName = getItemByProductRef(
                Object.keys(ingredient)[0]
              ).name;
              const total = calculateTotal(ingredient, data, lineId, productId);
              const scale = calculateScale(ingredient, data);

              const scrap = calculateScrap(ingredient, data, lineId, productId);
              const scrapPercent = getLineByProductRef(lineId).products.find(
                (item) => item[productId]
              )[productId].scrapPercent;

              const mixes =
                Object.keys(ingredient)[0].substring(
                  0,
                  Object.keys(ingredient)[0].indexOf("/")
                ) === "doughs"
                  ? calculateMixes(ingredient, data, lineId, productId)
                  : null;
              const mixMax = getItemByProductRef(
                Object.keys(ingredient)[0]
              ).maxMix;
              const mixMin = getItemByProductRef(
                Object.keys(ingredient)[0]
              ).minMix;
              // console.log("mixes ", mixes);
              // Generate mix times
              const mixTimes = [];

              // console.log("startTime ", startTime);
              // console.log("mixes ", mixes); 
              if (mixes > 0) {
                const mixDuration = getItemByProductRef(
                  Object.keys(ingredient)[0]
                ).mixTime; // Example duration for each mix in minutes
                const mixDuration2 = getItemByProductRef(
                  Object.keys(ingredient)[0]
                ).mixTime2; // Example duration for each mix in minutes
                const reposeTime = getItemByProductRef(
                  Object.keys(ingredient)[0]
                ).fermentTime; // Example repose time in minutes
                let piecesPerMix;
                for (let i = 0; i < mixes; i++) {
                  // console.log(" mixes ", mixes, " i ", i)
                  if (mixes - i < 1) {
                    piecesPerMix =
                      (mixMax * (mixes - i)) / Object.values(ingredient)[0];
                  } else {
                    piecesPerMix = mixMax / Object.values(ingredient)[0];
                  }
                  const startReposeTime = addMinutes(startTime, -reposeTime);
                  const startMixTime = addMinutes(
                    startReposeTime,
                    -mixDuration - mixDuration2
                  );

                  const piecesPerHour = getLineByProductRef(
                    lineId
                  ).products.find((item) => item[productId])[productId]
                    .piecesPerHour;
                  // console.log("piecesPerMix ",piecesPerMix, " piecesPerHour ", piecesPerHour)
                  // const endMixTime = addMinutes(startTime, mixTime);
                  const runTimeMins = Math.ceil(
                    (piecesPerMix / piecesPerHour) * 60
                  );
                  // console.log("runTimeNeeded ", runTimeMins)
                  mixTimes.push({
                    startTime,
                    startMixTime,
                    startReposeTime,
                    runTime: startTime,
                  });
                  startTime = addMinutes(startTime, runTimeMins); // Update start time for the next mix
                }
              }
              return {
                ingredientName,
                total,
                scale,
                scrap,
                scrapPercent,
                mixes,
                mixMax,
                mixMin,
                mixTimes,
                breakTime
              };
            });
            if (breakTime>0) {
              startTime = addMinutes(startTime, breakTime);
            }

            return {
              productName,
              quantity: Math.max(
                0,
                (data.quantity ?? 0) - (data.onHand ?? 0) + (data.extra ?? 0)
              ),
              onHand: data.onHand ?? 0,
              filledQuantity: data.quantity ?? 0,
              extra: data.extra ?? 0,
              unitName: data.unit.name,
              pieceCount: data.unit.pieceCount,
              totalPieces,
              ingredientDetails,
            };
            
          }
        );
        return { lineName, products: productDetails };
      });

      return { listName, lines: lineDetails };
    }
  );

  const generateMixerChartTable = () => {
    return summaryData.map((list) => (
      <div key={list.listName}>
        <strong>{list.listName}</strong>
        {list.lines.map((line, index) => (
          <div key={line.lineName}>
            <strong>Mix Schedule Line: {line.lineName}</strong>
            <table>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Dough</th>
                  <th>Mix </th>
                  <th>Repose </th>
                  <th>Start </th>
                </tr>
              </thead>
              <tbody>
                {line.products.map((product) =>
                  product.ingredientDetails.map((ingredient) =>
                    ingredient.mixTimes.map((mix, index) => {
                      const isLastMix = index === ingredient.mixTimes.length - 1;
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{product.productName}</td>
                            <td>
                              {ingredient.ingredientName} {index + 1}
                            </td>
                            <td>{mix.startMixTime}</td>
                            <td>{mix.startReposeTime}</td>
                            <td>{mix.startTime}</td>
                          </tr>
                          {isLastMix && ingredient.breakTime > 0 && (
                            <tr> 
                              <td>Break</td>
                              <td colSpan={3}></td>
                              <td>{ingredient.breakTime}</td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })
                  )
                )}
              </tbody>
            </table>
            {index + 1 < list.lines.length ? (
              <div className="page-break"></div>
            ) : null}
          </div>
        ))}
      </div>
    ));
  };

  console.log("summaryData ", summaryData);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        Production <br />
        {/* {console.log("lineProductQuantities ", documentData.lineProductQuantities)} */}
        {console.log("documentData ", documentData)}
        <label htmlFor="productionDate">Select Date:</label>
        <input
          type="date"
          id="productionDate"
          value={productionDate}
          onChange={(e) => setProductionDate(e.target.value)}
        />
        <label htmlFor="productionList">Select Production List:</label>
        <select
          id="productionList"
          onChange={(e) => {
            const selectedOptions = Array.from(
              e.target.selectedOptions,
              (option) => option.value
            );
            setDocumentData((prev) => ({
              ...prev,
              selectedProductionLists: [
                ...prev.selectedProductionLists,
                ...selectedOptions.filter(
                  (option) => !prev.selectedProductionLists.includes(option)
                ),
              ],
            }));
          }}
          multiple
        >
          {productionLists.map((product) => (
            <option key={product.id} value={product.id}>
              {product.name}
            </option>
          ))}
        </select>
        <button
          onClick={() =>
            setDocumentData((prev) => ({
              selectedProductionLists: [],
              lineProductQuantities: {},
              startTimes: {},
            }))
          }
        >
          Clear Selection
        </button>
        <div>
          <h3>Selected Production Lists:</h3>
          <ul>
            {documentData.selectedProductionLists.map((listId, index) => {
              const productionList = productionLists.find(
                (list) => list.id === listId
              );
              return productionList ? (
                <div key={listId}>
                  <li>
                    {productionList ? productionList.name : "not found"}
                    {index > 0 && (
                      <ArrowCircleUpIcon
                        onClick={() => moveProductionList(index, -1)}
                        className="icon"
                      />
                    )}
                    {index <
                      documentData.selectedProductionLists.length - 1 && (
                        <ArrowCircleDownIcon
                          onClick={() => moveProductionList(index, 1)}
                        />
                      )}
                    <RemoveCircleOutlineIcon
                      onClick={() => removeProductionList(listId)}
                    />
                  </li>
                  <ul>
                    {Object.entries(productionList.linesAndProducts).map(
                      ([index, lineAndProducts]) => (
                        <li key={index}>
                          Line:{" "}
                          {
                            getLineByProductRef(Object.keys(lineAndProducts)[0])
                              .name
                          }{" "}
                          Start Time:
                          <input
                            type="time"
                            onChange={(event) =>
                              handleStartTimeChange(
                                listId,
                                getLineByProductRef(
                                  Object.keys(lineAndProducts)[0]
                                ).id,
                                event.target.value
                              )
                            }
                            onKeyDown={(event) => handleEnterKey(event)}
                          />
                          <ul>
                            <table>
                              <thead>
                                <tr>
                                  <th>Product</th>
                                  <th>On Hand</th>
                                  <th>Quantity</th>
                                  <th>Extra</th>
                                  <th>Break</th>
                                  <th>Unit</th>
                                </tr>
                              </thead>
                              <tbody>
                                {lineAndProducts[
                                  Object.keys(lineAndProducts)[0]
                                ].map((id) => (
                                  <tr key={id}>
                                    <td>{getItemByProductRef(id).name}</td>
                                    <td>
                                      <input
                                        type="number"
                                        min={0}
                                        value={
                                          documentData.lineProductQuantities[
                                            listId
                                          ] &&
                                            documentData.lineProductQuantities[
                                            listId
                                            ][Object.keys(lineAndProducts)[0]] &&
                                            documentData.lineProductQuantities[
                                            listId
                                            ][Object.keys(lineAndProducts)[0]][
                                            id
                                            ] &&
                                            documentData.lineProductQuantities[
                                              listId
                                            ][Object.keys(lineAndProducts)[0]][id]
                                              .onHand
                                            ? documentData
                                              .lineProductQuantities[listId][
                                              Object.keys(lineAndProducts)[0]
                                            ][id].onHand
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleOnHandChange(
                                            listId,
                                            Object.keys(lineAndProducts)[0],
                                            id,
                                            e.target.value
                                          )
                                        }
                                        onKeyDown={(event) =>
                                          handleEnterKey(event)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        min={0}
                                        value={
                                          documentData.lineProductQuantities[
                                            listId
                                          ] &&
                                            documentData.lineProductQuantities[
                                            listId
                                            ][Object.keys(lineAndProducts)[0]] &&
                                            documentData.lineProductQuantities[
                                            listId
                                            ][Object.keys(lineAndProducts)[0]][
                                            id
                                            ] &&
                                            documentData.lineProductQuantities[
                                              listId
                                            ][Object.keys(lineAndProducts)[0]][id]
                                              .quantity
                                            ? documentData
                                              .lineProductQuantities[listId][
                                              Object.keys(lineAndProducts)[0]
                                            ][id].quantity
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleQuantityChange(
                                            listId,
                                            Object.keys(lineAndProducts)[0],
                                            id,
                                            e.target.value
                                          )
                                        }
                                        onKeyDown={(event) =>
                                          handleEnterKey(event)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        min={0}
                                        value={
                                          documentData.lineProductQuantities[
                                            listId
                                          ] &&
                                            documentData.lineProductQuantities[
                                            listId
                                            ][Object.keys(lineAndProducts)[0]] &&
                                            documentData.lineProductQuantities[
                                            listId
                                            ][Object.keys(lineAndProducts)[0]][
                                            id
                                            ] &&
                                            documentData.lineProductQuantities[
                                              listId
                                            ][Object.keys(lineAndProducts)[0]][id]
                                              .extra
                                            ? documentData
                                              .lineProductQuantities[listId][
                                              Object.keys(lineAndProducts)[0]
                                            ][id].extra
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleExtraChange(
                                            listId,
                                            Object.keys(lineAndProducts)[0],
                                            id,
                                            e.target.value
                                          )
                                        }
                                        onKeyDown={(event) =>
                                          handleEnterKey(event)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        min={0}
                                        value={
                                          documentData.lineProductQuantities[
                                            listId
                                          ] &&
                                            documentData.lineProductQuantities[
                                            listId
                                            ][Object.keys(lineAndProducts)[0]] &&
                                            documentData.lineProductQuantities[
                                            listId
                                            ][Object.keys(lineAndProducts)[0]][
                                            id
                                            ] &&
                                            documentData.lineProductQuantities[
                                              listId
                                            ][Object.keys(lineAndProducts)[0]][id]
                                              .break
                                            ? documentData
                                              .lineProductQuantities[listId][
                                              Object.keys(lineAndProducts)[0]
                                            ][id].break
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleBreakChange(
                                            listId,
                                            Object.keys(lineAndProducts)[0],
                                            id,
                                            e.target.value
                                          )
                                        }
                                        onKeyDown={(event) =>
                                          handleEnterKey(event)
                                        }
                                      />
                                    </td>
                                    <td>
                                      <select
                                        value={
                                          documentData.lineProductQuantities[
                                            listId
                                          ] &&
                                            documentData.lineProductQuantities[
                                            listId
                                            ][Object.keys(lineAndProducts)[0]] &&
                                            documentData.lineProductQuantities[
                                            listId
                                            ][Object.keys(lineAndProducts)[0]][
                                            id
                                            ] &&
                                            documentData.lineProductQuantities[
                                              listId
                                            ][Object.keys(lineAndProducts)[0]][id]
                                              .unit &&
                                            documentData.lineProductQuantities[
                                              listId
                                            ][Object.keys(lineAndProducts)[0]][id]
                                              .unit.name
                                            ? documentData
                                              .lineProductQuantities[listId][
                                              Object.keys(lineAndProducts)[0]
                                            ][id].unit.pieceCount
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleUnitChange(
                                            listId,
                                            Object.keys(lineAndProducts)[0],
                                            id,
                                            e.target.options[
                                            e.target.selectedIndex
                                            ]
                                          )
                                        }
                                        onKeyDown={(event) =>
                                          handleEnterKey(event)
                                        }
                                      >
                                        {getItemByProductRef(id).productUnits
                                          .length > 0 ? (
                                          <>
                                            {getItemByProductRef(
                                              id
                                            ).productUnits.map(
                                              (productUnit, index) => (
                                                <option
                                                  key={index}
                                                  name={productUnit.name}
                                                  value={productUnit.pieceCount}
                                                >
                                                  {productUnit.name}
                                                </option>
                                              )
                                            )}
                                            <option value={1} name={"piece"}>
                                              Pieces
                                            </option>
                                          </>
                                        ) : (
                                          <option value={1} name={"piece"}>
                                            Pieces
                                          </option>
                                        )}
                                      </select>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </ul>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              ) : null;
            })}
          </ul>
        </div>
        <button onClick={saveProductionData}>Save</button>
        <br />
        <br />
        <ReactToPrint
          trigger={() => <button>Print Summary</button>}
          content={() => componentRef.current}
        />
        <div style={{ display: "none" }}>
          <SummaryPrintableComponent
            ref={componentRef}
            summaryData={summaryData}
            day={getDayOfWeek(productionDate)}
            date={formatDate(productionDate)}
            generateMixerChartTable={generateMixerChartTable}
          />
        </div>{" "}
        {/* <ReactToPrint
          trigger={() => <button>Print Mix Chart</button>}
          content={() => componentRef.current}
        />
        <div style={{ display: "none" }}>
          <SummaryPrintableComponent
            ref={componentRef}
            summaryData={summaryData}
            day={getDayOfWeek(productionDate)}
            date={formatDate(productionDate)}
          />
        </div> */}
        <ReactToPrint
          trigger={() => <button>Print Labels</button>}
          content={() => labelsComponentRef.current}
        />
        <div style={{ display: "none" }}>
          <LabelsPrintableComponent
            ref={labelsComponentRef}
            summaryData={summaryData}
            day={getDayOfWeek(productionDate)}
            date={formatDate(productionDate)}
            generateMixerChartTable={generateMixerChartTable}
          />
        </div>
        {documentData.selectedProductionLists.length > 0 ? (
          <>
            <div>
              <h3>Summary:</h3>

              {Object.entries(documentData.lineProductQuantities).map(
                ([listId, lines]) => (
                  <div key={listId}>
                    Production List:{" "}
                    {/* {console.log("productionLists ", productionLists)} */}
                    {productionLists.find((list) => list.id === listId)
                      ? productionLists.find((list) => list.id === listId).name
                      : "Not Found"}
                    <ul>
                      {Object.entries(lines).map(([lineId, products]) => (
                        <li key={lineId}>
                          Line: {getLineByProductRef(lineId).name} Start Time:{" "}
                          {documentData.startTimes[listId]?.[lineId] ||
                            "Not Set"}
                          {/* {console.log("lineId ", lineId)} */}
                          <ul>
                            {Object.entries(products).map(
                              ([productId, data], index) => (
                                <li key={productId}>
                                  {getItemByProductRef(productId).name}:{" "}
                                  {/* Usin only Quantity */}
                                  {/*
                                {data.quantity} {data.unit.name}s */}
                                  {
                                    //GET THE EXTRA AND ON HAND HERE
                                  }
                                  {Math.max(
                                    0,
                                    (data.quantity ?? 0) -
                                    (data.onHand ?? 0) +
                                    (data.extra ?? 0)
                                  )}{" "}
                                  {data.unit.name}({data.unit.pieceCount}) TOTAL
                                  PIECES:{" "}
                                  {/* {data.quantity * data.unit.pieceCount} */}
                                  {Math.max(
                                    0,
                                    (data.quantity ?? 0) -
                                    (data.onHand ?? 0) +
                                    (data.extra ?? 0)
                                  ) * data.unit.pieceCount}
                                  {index > 0 ? (
                                    <ArrowCircleUpIcon
                                      onClick={() =>
                                        moveProductUp(listId, lineId, productId)
                                      }
                                      className="icon"
                                    />
                                  ) : null}
                                  {index + 1 <
                                    Object.entries(products).length ? (
                                    <ArrowCircleDownIcon
                                      onClick={() =>
                                        moveProductDown(
                                          listId,
                                          lineId,
                                          productId
                                        )
                                      }
                                      className="icon"
                                    />
                                  ) : null}
                                  <ul>
                                    {Object.entries(
                                      getItemByProductRef(productId).ingredients
                                    ).map(([index, ingredient]) => (
                                      <>
                                        <li>
                                          <strong>
                                            {
                                              getItemByProductRef(
                                                Object.keys(ingredient)[0]
                                              ).name
                                            }{" "}
                                            {calculateTotal(
                                              ingredient,
                                              data,
                                              lineId,
                                              productId
                                            )}
                                          </strong>

                                          {calculateScale(ingredient, data)}

                                          {calculateScrap(
                                            ingredient,
                                            data,
                                            lineId,
                                            productId
                                          )}
                                          {Object.keys(ingredient)[0].substring(
                                            0,
                                            Object.keys(ingredient)[0].indexOf(
                                              "/"
                                            )
                                          ) === "doughs" ? (
                                            <>
                                              <br></br>
                                              {calculateMixes(
                                                ingredient,
                                                data,
                                                lineId,
                                                productId
                                              )}
                                              {" Mixes @ " +
                                                getItemByProductRef(
                                                  Object.keys(ingredient)[0]
                                                ).maxMix /
                                                MASS_UNITS[0].amount +
                                                MASS_UNITS[0].abbreviation +
                                                " "}
                                            </>
                                          ) : null}
                                        </li>
                                      </>
                                    ))}
                                  </ul>
                                </li>
                              )
                            )}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </div>
                )
              )}
            </div>
            <div>
              <h3>Mixer Chart</h3>
              {generateMixerChartTable()}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Production;

